<template>
    <div>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item>
                <van-image
                    width="100%"
                    :src="require('../../assets/img/activate/banner_reveal.png')"
                 />

            </van-swipe-item>
            <van-swipe-item>
                 <van-image
                    width="100%"
                    :src="require('../../assets/img/activate/banner_pardon.png')"
                 />
            </van-swipe-item>
        </van-swipe>
        <!-- 品牌区域 -->
		<div class="brand-tiele">请选择对应品牌（步骤一）</div>
        <ul class="brand-wrap">
			<li class="brand-item" @click="brandActive(1)"  :class="{active: activeNum == 1}" param="chcnav">
				<span>华测</span>
				<p>S8、S10</p>
			</li>
			<li class="brand-item" @click="brandActive(2)"  :class="{active: activeNum == 2}"  param="hitarget">
				<span>中海达</span>
				<p>D6、D8、D8Pro</p>
			</li>
			<li class="brand-item" @click="brandActive(3)" :class="{active: activeNum == 3}" param="south">
				<span>南方</span>
				<p>极致</p>
			</li>
			<li class="brand-item" @click="brandActive(4)" :class="{active: activeNum == 4}"  param="ufo">
				<span>合众思壮</span>
				<p>F3Pro</p>
			</li>
      <li class="brand-item" @click="brandActive(5)" :class="{active: activeNum == 5}"  param="maplays">
        <span>测绘玩家</span>
        <p>A10</p>
      </li>
		</ul>
        <!-- 品牌区域 e-->
		<div class="brand-tiele">请输入正确的机身码（步骤二）</div>
		<!-- 输入区域 s-->
		<div class="sub-box">
			<!-- 华测 -->
			<div class="sub-boc" v-show="activeNum == 1">
				<van-cell-group>
                     <van-field v-model="value" placeholder="请输入华测机身码7位数字" />
                </van-cell-group>
				<button @click="chcnavDefine()">确定</button>
			</div>
			<!-- 中海达 -->
			<div class="sub-boc" v-show="activeNum == 2">
				<van-cell-group>
                     <van-field v-model="value" placeholder="请输入中海达机身码" />
                </van-cell-group>
				<button @click="showHitargetDefine()">确定</button>
			</div>
			<!-- 南方极致 -->
			<div class="sub-boc" v-show="activeNum == 3">
				<van-cell-group>
                     <van-field v-model="value" placeholder="请输入极致机身码" />
                </van-cell-group>
				<button @click="southtDefine()">确定</button>
			</div>
			<!-- 合众思壮 -->
			<div class="sub-boc" v-show="activeNum == 4">
				<van-cell-group>
                     <van-field v-model="value" placeholder="请输入F3Plus机身码" />
                </van-cell-group>
				<button @click="unistrongDefine()">确定</button>
			</div>
      <!-- 测绘玩家A10，思南 -->
      <div class="sub-boc" v-show="activeNum == 5">
        <van-cell-group>
          <van-field v-model="value" placeholder="请输入设备机身码" />
        </van-cell-group>
        <button @click="MaplaysDefine()">确定</button>
      </div>
			<!-- 提示 -->
			<div class="sub-boc"  v-show="activeNum === 6&&activeNum === 7">
				<div class="unselected">
					请先选择要激活产品的品牌
				</div>
			</div>
		</div>

    <!-- 激活码记录 s-->
		<div class="record-wrap" style="margin-bottom: 100px;">
			<div class="record-head">激活码记录</div>
			<div id="registration_id" class="record-body" >
				<div class="record-boc" v-for="item in this.recordActiveCode" :key="item.id">
					<div class="record-item" >
						<p>机身码：{{item.sn}}</p>
						<span>申请时间：{{ item.uptime.substring(0,10) }}</span>
					</div>
					<div class="record-item">
						<p style="word-wrap:break-word;width: 50%;">激活码：{{item.registration_code.indexOf(":") === -1 ? item.registration_code : item.registration_code.indexOf('FUNCTIONREG')!==-1? item.registration_code :item.registration_code.substring(8,item.registration_code.length)}}</p>
            <div style="text-align: center">
              <button @click="tapCopy(item.registration_code.indexOf(':') === -1 ? item.registration_code :item.registration_code.indexOf('FUNCTIONREG')!==-1? item.registration_code : item.registration_code.substring(8,item.registration_code.length))">复制</button>
              <button @click="qrCode(item.registration_code.indexOf(':') === -1 ? item.registration_code :item.registration_code.indexOf('FUNCTIONREG')!==-1? item.registration_code : item.registration_code.substring(8,item.registration_code.length))">二维码</button>
            </div>
          </div>

        </div>
			</div>
		</div>
      <div class="codeBackground" v-show="imgCodeShow" @click="imgCodeShow=false">
        <img :src="imgCode">
      </div>
		<!-- 激活码记录 e-->
    </div>
</template>
<script>
import {Dialog, Notify} from "vant";
    import api from "@/utils/api";
import _ from "lodash";

    export default {
        data() {
           return {
            value: "",
            activeNum: '',
            brandParam : '',
            recordActiveCode : [],
             imgCode:'',
             imgCodeShow:false
           }
        },
        async created() {
         // window.localStorage.setItem('token','eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJUZXN0VGV4dCI6WyLov5nph4zmmK_mtYvor5Xph4zpnaLliqDlhaXnmoTnibnmrormlbDmja7vvIEiLCLnjovkvbPmtYvor5XvvIzlnKhhdXRo5o6I5p2D5Lit5b-D6YeM6Z2i77yBIl0sImF1ZCI6WyJtYXBsYXlzLW1vYmlsZS1jbGllbnQiLCJtYXBsYXlzLWF1dGhjZW50ZXIiLCJtYXBsYXlzLXVzZXItc2VydmVyIiwibWFwbGF5cy1ndWFyYW50ZWUtc2VydmVyIl0sInVzZXJfbmFtZSI6IjE4OTgwOTY5NjYzIiwic2NvcGUiOlsibGxsempkIiwidXNlciIsInZzZXJ2aWNlIiwiY29tbW9uIl0sImV4cCI6MTY2NjU4MDc1MiwiYXV0aG9yaXRpZXMiOlsiUk9MRV9jb21tb24iLCJjb21tb246dXNlciJdLCJqdGkiOiI5MTYwNzgwOC0zOTQ4LTQ1ZjAtYTllMC0yZmZkYTE3MmM2Y2EiLCJjbGllbnRfaWQiOiJtYXBsYXlzIn0.Di8YOoDd8My2hpOcXHa8bSW3YCmG3rI69RkaPqMHXkexki2pRCuCUpujUWV6XtqTbQ9qQus6l-TgaS4XzxviekwDY70_dMH9-aqnbb9Hv-Y_ulnN5LRYYx69vyalBTsEG6Pz-Afe7q-6wvVcFVgMMVad9qjrr7iub1UUES2aUeMiWDVddYcvxir3b9b8cZ-0EJ7Hn6DylMI13zk7TDMgG71VXQS2y5gecL0niKeHWEA6KDX2QBeGZzBbtidrt7eqESUEhy65jgOr3jcrLjLz-fgmrQ_swZqh41yGw0sFYxNMKpAQUyQZW_x8moN78a-Qamrk6irboqMpejdkjfhDkA')
          const { data } = await api.getUserActiveCode({username : window.localStorage.getItem('phone')});
          this.recordActiveCode = data;
        },
        methods: {
            brandActive: function(activeNum) {
                this.activeNum = activeNum;
                if (activeNum === 1){
                  this.brandParam = 'chcnav'
                }else if (activeNum === 2){
                  this.brandParam = 'hitarget'
                }else if (activeNum === 3){
                  this.brandParam = 'south'
                }else if (activeNum === 4){
                  this.brandParam = 'ufo'
                }else if (activeNum === 5){
                  this.brandParam = 'maplays'
                }
                if (activeNum === 6 || activeNum === 7){
                  Dialog.alert({
                    message: '暂未开放,敬请期待',
                    theme: 'round-button',
                  }).then(() => {
                    // on close
                  });
                }
            },
            async chcnavDefine(){
                if (this.isEmpty(this.value)){
                  if (/^(\d{7})?$/.test(this.value)){
                    let res = await api.getChcnavDefine({username:window.localStorage.getItem('phone'),brand:this.brandParam,sn:this.value})
                    if (res.code === 0){
                      if (res.data.status === 1){
                        Dialog.alert({
                          message: '激活成功激活码为:' + (res.data.data.indexOf(":") === -1 ? res.data.data : res.data.data.substring(8,res.data.data.length)),
                          theme: 'round-button',
                        }).then(() => {
                          window.location.reload()
                          // on close
                        });
                      }else if (res.data.status === 400){
                        Notify({ type: 'danger', message: '激活码有误' });
                      }else{
                        Notify({ type: 'danger', message: res.data.msg });
                      }
                    }else{
                      Dialog.alert({
                        message: '请输入华测机身码7位数字',
                        theme: 'round-button',
                      }).then(() => {
                        // on close
                      });
                    }
                  }else {
                    Dialog.alert({
                      message: '请输入华测机身码7位数字',
                      theme: 'round-button',
                    }).then(() => {
                      // on close
                    });
                  }
                }else {
                  Dialog.alert({
                    message: '请输入机身码',
                    theme: 'round-button',
                  }).then(() => {
                    // on close
                  });
                }
            },
          showHitargetDefine: _.debounce(async function(){
              let _this=this;
              if (this.isEmpty(this.value)){
                if (/^(\d{8})?$/.test(this.value)){
                  let res= await api.getChcnavDefine({username:window.localStorage.getItem('phone'),brand:this.brandParam,sn:this.value})
                  //let res = await api.getChcnavDefine({username:window.localStorage.getItem('phone'),brand:this.brandParam,sn:this.value})
                  if (res.code === 0){
                    if (res.data.status === 200){
                      Dialog.alert({
                        message: '激活成功激活码为:' + res.data.license,
                        theme: 'round-button',
                      }).then(() => {
                        window.location.reload()
                        // on close
                      });
                    }else if (res.data.status === 404){
                      Notify({ type: 'danger', message: '激活码有误' });
                    }else{
                      Notify({ type: 'danger', message: res.data.msg });
                    }
                  }else{

                    Dialog.alert({
                      message: '每个月只有2次机会获取激活码',
                      theme: 'round-button',
                    }).then(() => {
                      // 执行后
                    });
                  }
                }else {
                  Dialog.alert({
                    message: '请输入中海达身码8位数字',
                    theme: 'round-button',
                  }).then(() => {
                    // on close
                  });
                }
              }else {
                Dialog.alert({
                  message: '请输入机身码',
                  theme: 'round-button',
                }).then(() => {
                  // on close
                });
              }

          }, 3000, {leading:true,trailing:false}),
          async southtDefine(){
              console.log(this.value);
              if (this.isEmpty(this.value)){
                if (/^[a-zA-Z0-9]{15,20}$/.test(this.value)){
                  let res = await api.getChcnavDefine({username:window.localStorage.getItem('phone'),brand:this.brandParam,sn:this.value})
                  if (res.code === 0){
                    if (res.code === 0){
                      Dialog.alert({
                        message: '激活成功激活码为:' + res.data.split(",")[2],
                        theme: 'round-button',
                      }).then(() => {
                        window.location.reload()
                        // on close
                      });
                    }else{
                      Notify({ type: 'danger', message: res.data.msg });
                    }
                  }else{
                    Dialog.alert({
                      message: '请输入南方极致身码字符串',
                      theme: 'round-button',
                    }).then(() => {
                      // on close
                    });
                  }
                }else {
                  Dialog.alert({
                    message: '请输入南方极致身码字符串',
                    theme: 'round-button',
                  }).then(() => {
                    // on close
                  });
                }
              }else {
                Dialog.alert({
                  message: '请输入机身码',
                  theme: 'round-button',
                }).then(() => {
                  // on close
                });
              }
            },
          async unistrongDefine(){
              console.log(this.value);
              if (this.isEmpty(this.value)){
                if (/^[a-zA-Z0-9]{13,20}$/.test(this.value)){
                  let res = await api.getChcnavDefine({username:window.localStorage.getItem('phone'),brand:this.brandParam,sn:this.value})
                  if (res.code === 0){
                      Dialog.alert({
                        message: '激活成功激活码为:' + res.data,
                        theme: 'round-button',
                      }).then(() => {
                        window.location.reload()
                        // on close
                      });
                  }else {
                    Dialog.alert({
                      message: res.msg,
                      theme: 'round-button',
                    }).then(() => {
                      // on close
                    });
                  }
                }else {
                  Dialog.alert({
                    message: '请输入F3plus身码字符串',
                    theme: 'round-button',
                  }).then(() => {
                    // on close
                  });
                }
              }else {
                Dialog.alert({
                  message: '请输入机身码',
                  theme: 'round-button',
                }).then(() => {
                  // on close
                });
              }


            },
          async MaplaysDefine(){
              // 思南A10,测绘玩家
            // console.log(this.value);
            if (this.isEmpty(this.value)){
              if (/^[a-zA-Z0-9]{8,12}$/.test(this.value)){
                let res = await api.getChcnavDefine({username:window.localStorage.getItem('phone'),brand:this.brandParam,sn:this.value})
                if (res.code === 0){
                  if (res.code === 0){
                    Dialog.alert({
                      message: '激活码:' + res.data.data.tempRegCode,
                      theme: 'round-button',
                    }).then(() => {
                      window.location.reload()
                      // on close
                    });
                  }else{
                    Notify({ type: 'danger', message: '激活码有误' });
                  }
                }else{
                  Dialog.alert({
                    message: '请输入正确的机身码字符串',
                    theme: 'round-button',
                  }).then(() => {
                    // on close
                  });
                }
              }else {
                Dialog.alert({
                  message: '请输入正确的机身码字符串',
                  theme: 'round-button',
                }).then(() => {
                  // on close
                });
              }
            }else {
              Dialog.alert({
                message: '请输入机身码',
                theme: 'round-button',
              }).then(() => {
                // on close
              });
            }
          },
            isEmpty(str){
              return str !== null && str !== undefined && str.trim() !== '' && str.length > 0;
            },
            tapCopy(rCode) {
              let aux = document.createElement("input");
              aux.setAttribute("value", rCode);
              document.body.appendChild(aux);
              aux.select();
              var content = window.getSelection().toString();
              document.execCommand("Copy");
              document.body.removeChild(aux);
              alert("注册码复制成功！");
            },
            async qrCode(value){
              let {code,data}=await api.getCode({code:value})
              if(code==0){
                this.imgCodeShow=true
                this.imgCode=data
              }
            }
        }
    }
</script>
<style lang="less" scoped>
    .brand-tiele {
        padding: 0 30px;
        height: 60px;
        line-height: 60px;
        font-size: 32px;
        color: #333;
    }


    .sub-box {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        margin: 20px 30px;
        padding: 10px 16px;
        .sub-boc {
            padding: 5px 0;

            .unselected {
                text-align: center;
                font-size: 28px;
                height: 60px;
                line-height: 60px;
                color: #666;
            }
        }
        button {
            background: #FFB721;
            border: 0;
            height: 80px;
            line-height: 80px;
            color: #FFFFFF;
            border-radius: 10px;
            font-size: 32px;
            padding: 0 30px;
            display: block;
            width: 100%;
            margin-top: 40px;
        }
    }
    .brand-wrap {
        display: -webkit-flex;
        display: flex;
        flex-wrap:wrap;
        justify-content:space-between;
        padding: 0 30px;
        margin: 20px 0;
        .brand-item {
            padding: 20px;
            width: calc(50% - 50px);
            margin-bottom: 10px;
            background: #EBEBEB url(../../assets/img/activate/brand_bg.png) no-repeat bottom right;
            border-radius: 10px;
            span {
                color: #666666;
                display: block;
                font-size: 32px;
                margin-bottom: 20px;
            }
            p {
                font-size: 28px;
                color: #999;
            }

            &.active {
                background: #FFAC00 url(../../assets/img/activate/brand_bg.png) no-repeat bottom right;
            }
            &.active span {
                color: #FFFFFF;
            }
            &.active p {
                color: #FFFFFF;
            }
        }
    }

    /* 激活码记录 s*/
.record-wrap {
	margin: 20px;
	padding: 10px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
}
.record-wrap .record-head {
	font-size: 28px;
	line-height: 48px;
	color: #333333;
	border-bottom: 1px solid #ddd;
}
.record-wrap .record-boc {
	border-bottom: 1px solid #ddd;
	padding: 6px 0;
}
.record-wrap .record-item {
	display: -webkit-flex;
	display: flex;
	padding: 5px 0;
  width: 100%;
}
.record-wrap p {
	font-size: 28px;
	color: #666666;
	-webkit-flex: 1;
	flex: 1;
}
.record-wrap .record-item span {
	font-size: 28px;
	color: #333333;
}
.record-wrap .record-item button {
  margin-left: 8px;
	height: 40px;
	background: #E1E1E1;
	padding: 0 20px;
	border-radius: 8px;
	border: 0;
	font-size: 24px;
	color: #666666;
}

.codeBackground{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.1);
    img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
}
/* 激活码记录 e*/
</style>
